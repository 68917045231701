/**
 * Constructs query string from object.
 * @param params
 * @returns {string}
 */
const buildQueryString = params => {
    if (typeof params === 'string') return params
    return Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&')
}

export default buildQueryString

import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { MIDDLEWARE_API_DEV, MIDDLEWARE_API_PROD, IS_DEV } = publicRuntimeConfig

const MIDDLEWARE_API = IS_DEV ? MIDDLEWARE_API_DEV : MIDDLEWARE_API_PROD

const getApiURL = endpoint =>
    ({
        boat: `${MIDDLEWARE_API}/boat`,
        boats: `${MIDDLEWARE_API}/boats`,
        newsletter: `${MIDDLEWARE_API}/newsletter`,
        lead: `${MIDDLEWARE_API}/lead`
    }[endpoint] || MIDDLEWARE_API)

export default getApiURL

import { fetchJson } from 'helpers/wordpress'
import { getApiURL } from 'helpers/middleware'

const submitLeadForm = async formData => {
    const data = await fetchJson(`${getApiURL('lead')}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formData })
    })

    return data
}

export default submitLeadForm

import getConfig from 'next/config'
import { buildQueryString } from 'helpers/wordpress'
import { getApiURL } from 'helpers/middleware'

const { publicRuntimeConfig } = getConfig()
const { APP_VERSION } = publicRuntimeConfig

/**
 * Get single boat by slug.
 * @param slug
 * @param queryParams
 * @returns string
 */
const getBoatApiURL = (slug, queryParams = {}) => {
    const params = { ...queryParams, slug }
    return `${getApiURL('boat')}?version=${APP_VERSION}${params ? `&${buildQueryString(params)}` : ''}`
}

export default getBoatApiURL

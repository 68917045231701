import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { WP_API } = publicRuntimeConfig

const getApiURL = endpoint =>
    ({
        posts: `${WP_API}/wp/v2/posts`,
        pages: `${WP_API}/wp/v2/pages`,
        categories: `${WP_API}/wp/v2/categories`,
        menus: `${WP_API}/menus/v1/menus`
    }[endpoint] || WP_API)

export default getApiURL

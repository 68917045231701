module.exports = (i18n, lngPathCorrector) => {
    let defaultLng = 'en'
    try {
        defaultLng = i18n.options.defaultLanguage
    } catch (e) {
        //
    }

    const buyPath = {
        en: 'buy',
        de: 'kaufen'
    }

    const typePath = {
        'Motor Yacht': {
            en: 'motor-yacht',
            de: 'motoryacht'
        },
        'Sailing Yacht': {
            en: 'sailing-yacht',
            de: 'segelyacht'
        },
        // eslint-disable-next-line prettier/prettier
        'RIB': {
            en: 'rib',
            de: 'rib'
        }
    }
    const defaultTypePath = 'Motor Yacht'

    const getBuyPath = lng => {
        return buyPath[lng] || buyPath[defaultLng]
    }

    const getTypePath = (lng, type = defaultTypePath) => {
        let path = typePath[type]
        if (!path) {
            Object.values(typePath).forEach(languages => {
                Object.values(languages).forEach(key => {
                    if (type === key) {
                        path = languages
                    }
                })
            })
        }
        if (!path) {
            path = typePath[defaultTypePath]
        }
        return path[lng] || path[defaultLng]
    }

    const getBoatLink = (type, slug, language, corrected = false) => {
        const lng = language || i18n.language || defaultLng

        const query = {
            buy: getBuyPath(lng),
            type: getTypePath(lng, type),
            slug
        }
        const pathname = '/[buy]/[type]/[slug]'
        const asChunks = [query.buy, query.type, query.slug]
        const as = `/${asChunks.join('/')}`

        const link = {
            href: {
                pathname,
                query
            },
            as
        }

        if (corrected) {
            return lngPathCorrector(i18n.options, link, lng)
        }

        return link
    }

    return getBoatLink
}

import fetch from 'isomorphic-unfetch/index'

/**
 * Universal fetch wrapper for client & server side
 * @param url
 * @param options
 * @returns {Promise<{data: any}|{err: *}>}
 */
const fetchJson = async (url, options = {}) => {
    let response
    if (typeof url !== 'string') {
        response = { err: 'Please provide string for URL.' }
        return response
    }
    try {
        const data = await fetch(url, {
            mode: 'cors',
            credentials: 'same-origin',
            ...options
        })
        response = {
            data: await data.json(),
            ...(data.headers.get('x-wp-totalpages') && {
                totalPages: parseInt(data.headers.get('x-wp-totalpages') || '0', 10)
            }),
            ...(data.headers.get('x-wp-total') && { totalPosts: parseInt(data.headers.get('x-wp-total') || '0', 10) })
        }
    } catch (e) {
        response = { err: e.message }
    }
    return response
}

export default fetchJson

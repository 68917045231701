import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { CLOUDINARY_URL } = publicRuntimeConfig

const getSize = size => {
    switch (size) {
        case 'medium':
            return 'w_640,h_auto,q_auto:best,f_auto,fl_progressive,c_fill,g_center'
        case 'listing':
            return 'w_340,h_230,q_auto,f_auto,fl_progressive,c_thumb,g_center,dpr_auto'
        case 'featured':
            return 'w_640,h_380,q_auto:best,f_auto,fl_progressive,c_thumb,g_center,dpr_auto'
        case 'thumbnail':
            return 'w_150,h_auto,q_auto:best,f_auto,fl_progressive,c_thumb,g_auto'
        case 'layout':
            return 'w_1640,h_860,q_auto:best,f_auto,fl_progressive,c_fill/c_scale,g_auto,dpr_auto'
        case 'interior-exterior':
            return 'w_1280,h_860,q_auto:best,f_auto,fl_progressive,c_fill/c_scale,g_auto,dpr_auto'
        default:
            return 'w_1640,h_640,q_auto:best,f_auto,fl_progressive,c_fill/c_scale,g_auto,dpr_auto'
    }
}

const getVersion = image => {
    if (!image || !image.updatedAt) {
        return ''
    }
    return `/v${image.updatedAt}`
}

const getImageURL = (images = {}, { position, size, returnList = false, order = 0, hasFallback = false }) => {
    if (returnList) {
        if (images[position] && images[position].length > 0) {
            return images[position]
                .filter(image => image?.order)
                .sort((a, b) => a.order - b.order)
                .map(sortedImage => ({
                    url: `${CLOUDINARY_URL}${getSize(size)}${getVersion(sortedImage)}/${sortedImage.publicId}.${
                        sortedImage.format
                    }`,
                    order: sortedImage.order
                }))
        }
        return []
    }

    let imageObj =
        (images[position] || []).find(image => image.order === `${order}` || image.order === `${order + 1}`) || null

    if (!imageObj) {
        if (images[position] && images[position].length > 0) {
            imageObj = images[position][0]
        } else if (position === 'hero' && hasFallback && images.listing.length > 0) {
            imageObj = images.listing[0]
        } else if (position === 'listing' && hasFallback && Array.isArray(images.hero) && images.hero.length > 0) {
            imageObj = images.hero[0]
        } else {
            imageObj = {}
        }
    }

    const { publicId, format } = imageObj

    if (publicId && format) return `${CLOUDINARY_URL}${getSize(size)}${getVersion(imageObj)}/${publicId}.${format}`
    // Missing Image
    return `${CLOUDINARY_URL}${getSize(size)}/v1582624731/Static/missing-image_djoivj.jpg`
}

export default getImageURL
